export const MINIMUM_CHROME_VERSION = 93;
export const SENSOR_DATA_INTERVAL = 0.1; // (seconds) how often to receive sensor info
export const SENSOR_STATUS_INITIALIZE_INTERVAL = 1.0; // (seconds) how often to get updates during calibration
export const SENSOR_STATUS_REGULAR_INTERVAL = 20.0; // (seconds) how often to update sensors in normal gameplay
export const RETRY_TIMEOUT = 45e3; // general timeout for disconnect

export const ACTIVITY_EVENT_RETRY_TIME = RETRY_TIMEOUT;

export const API_INIT_ACTIVITY_TIMEOUT = 20e3; // How long to wait for activity chagnes
export const API_START_ACTIVITY_TIMEOUT = 8e3;
export const API_END_ACTIVITY_AFTER_REWARDS_TIMEOUT = 20e3;
export const API_APK_LAUNCHED_TIMEOUT = 20e3; // how long to wait for an ApkLaunched after a LaunchApk.
export const SESSION_REWARDS_STARTED_TIMEOUT = 20e3; // how long to wait for SessionRewardsStarted.
export const SESSION_FINISHED_TIMEOUT = 60e3; // how long to wait for SessionFinished after a SessionRewardsStarted.

export const PING_RETRY_TIME = RETRY_TIMEOUT;
export const HEARBEAT_TIMEOUT = 6e3; // how long to wait for a ping response
export const HEARTBEAT_TRIES = Math.ceil((PING_RETRY_TIME / 1e3) / (HEARBEAT_TIMEOUT / 1e3)); // how many consecutive timeouts signal a failure
export const SENSOR_STATUS_ALL_TIMEOUT = 15e3; // how long to wait before assuming dead subscriptions and resubscribe
export const API_RETRY_TIME = RETRY_TIMEOUT;
export const API_TIMEOUT = 15e3; // (ms) How long to wait for Game apis to return before throwing Timeout exception
export const API_SHOW_WARNING_ON_FAILURE_NUMBER = 1; // Show disconnect warning starting with this number of failures
export const API_TIME_SLOW_RESPONSE_THRESHOLD = 8e3; // anything longer than this will be tracked in analytics
export const API_TIMEOUT_RETRIES = Math.ceil((API_RETRY_TIME / 1e3) / (API_TIMEOUT / 1e3)); // how many times to retry if we get a timeout
export const API_QUICK_TIMEOUT = 3e3; // used for resubscribing primarily
export const APOLLO_RETRY_TIME = RETRY_TIMEOUT;
export const APOLLO_DELAY_BETWEEN_RETRIES = 2e3; // Pause between retrying
export const APOLLO_NETWORK_FAILURE_TIME = 10; // about how long it takes to get a network failure - very quick it seems
export const APOLLO_LINK_RETRIES = Math.ceil((APOLLO_RETRY_TIME / 1e3) / ((APOLLO_NETWORK_FAILURE_TIME + APOLLO_DELAY_BETWEEN_RETRIES) / 1e3));
export const APOLLO_TOTAL_TIMEOUT = APOLLO_LINK_RETRIES * (APOLLO_NETWORK_FAILURE_TIME + APOLLO_DELAY_BETWEEN_RETRIES);

export const CONNECTION_POLLING_FREQUENCY = 1e3; // How frequently to poll for a connection when there is none
export const NETWORK_SETUP_URL_ORIGINAL = 'http://10.220.8.1/'; // Only used if the tablet apk doesn't provide a value
export const NETWORK_SETUP_URL = 'http://192.168.0.1/'; // Only used if the tablet apk doesn't provide a value
export const ALLOW_RUNNING_WITHOUT_SENSORS = false; // useful for development
export const IDLE_LOGOUT_MINUTES = 20; // If the user is inactive for this long, automatic logout
export const IDLE_WARNING_MINUTES = 3; // The warning will display for this long before logout occurs
export const NUMBER_OF_ALLOWED_FEEDBACK_CHARS = 500; // Number of allowed characters in the user feedback text field.
export const VERBOSE_LOGS = true;
export const SPECTATOR_VIEW_WIDTH = 675;
export const SPECTATOR_VIEW_HEIGHT = 480;
export const SETTINGS_DRAWER_WIDTH = 432;
export const SELECTOR_DRAWER_WIDTH = 432;
export const MUI_SLIDER_TOTAL = 100; // material UI expects slider values to be shares out of 100.
export const GAME_SOCKET_WAIT_BEFORE_SEND = 0;
export const GAME_API_USE_MUTEX = false;
export const LOG_DATABASE_CALLS = true;
export const REMOTE_LOG_URL = null;
export const PING_TO_CHECK_FOR_CONNECTIVITY = true;
export const LOG_PINGS = false;
export const LOG_MUTEX_LOCKS = false;
export const SKIN_TONES = ['#E8CAB3', '#DDB594', '#C5A480', '#C4907C', '#946051', '#6F5654'];
export const GENDER_TYPES = ['female', 'male'];
export const SYSTEM_UPDATE_INFO_POLLING_FREQUENCY = 2e3;
// export const REMOTE_LOG_URL = 'http://192.168.0.102:8000';
export const DEFAULT_AVATAR_COLOR = 2; // set actual default in setAvatar component.
export const DEFAULT_AVATAR_GENDER = GENDER_TYPES[0];
export const DEFAULT_AVATAR_HEIGHT = 163; // cm
export const DEFAULT_AVATAR_BODY_MASS = 0.5;
export const DEFAULT_AVATAR = {
  SkinTone: DEFAULT_AVATAR_COLOR,
  Gender: DEFAULT_AVATAR_GENDER,
  Height: DEFAULT_AVATAR_HEIGHT,
  BodyMass: DEFAULT_AVATAR_BODY_MASS,
  RightToLeft: 0, // mirror
  Activation: 0, // mirror
};
export const DEFAULT_PATIENT_BIRTHDATE = new Date(Date.UTC(1960, 0, 1));
export const DEFAULT_PATIENT_WEIGHT = 75;
export const DEFAULT_PATIENT_HEIGHT = 170;

export const API_VERSION_1 = '1.0.0.0';
export const API_VERSION_2 = '2.0.0.0';
export const API_VERSION_3 = '3.0.0.0';
export const API_VERSION_4 = '4.0.0.0';
export const API_VERSION_5 = '5.0.0.0';
export const API_VERSION_6 = '6.0.0.0';
export const API_VERSION_7 = '7.0.0.0';
export const API_VERSION_8 = '8.0.0.0';
export const CURRENT_API_VERSION = API_VERSION_8;
export const IGNORE_UPDATES = false;
export const SLEEP_TRIGGER_INTERVAL = 9e3; // Delay used to trigger a sleep event (SleepDetector)
export const TIME_TO_WAIT_FOR_LOGS_TO_FINISH_PROCESSING_BEFORE_UPLOADING_LOGS = 1e3;
export const AAD_DEVELOPMENT_APP_ID = '0118be64-41a5-478a-8e42-5fca0bd98327';
export const AAD_DEVELOPMENT_TENANT_ID = 'mvi0162Tenant.onmicrosoft.com';

export const SEND_SCREENCAST_ON_OFF = false; // whether cycling through hmd should turn off and on screencast
export const DELAY_BEFORE_EXIT_IF_ERROR = 5e3; // time needed to log any errors
export const FREQUENCY_OF_LOG_UPLOADS = 10e3; // how frequently we need to check to see if we need to upload log
export const CURRENT_RULES_VERSION = '1.0.0.0'; // see canned rules
export const DEBUG_MENU_CLICKS = 5; // how many clicks to bring up debug menu (if it's enabled)
export const HASURA_SUBSCRIPTIONS_ENABLED = false; // we currently don't use them, but there's code in apolloClient.js which can support them
export const IGNORE_SENSORS_WHEN_INITIALIZING = true; // whether to do special code to ignore sensor data when switching activities
export const IGNORE_SENSORS_BEFORE_PATIENT_READY = true; // whether to not record sensor data before patient ready clicked
export const NO_BLOB_UPLOADS = false; // disable all blob uploads
export const PLAY_SOUNDS = false;
export const USE_CONSOLE_GROUPS_IN_APOLLO_LOGS = false;// usually false, unless you only care about chrome's console and not console-feed
export const OFFLINE_MODE_FULL_PHI_USER = 'doctorpenumbra@gmail.com';
export const OFFLINE_MODE_LIMITED_PHI_USER = 'techpenumbra@gmail.com';
export const ENABLE_SCOREBOARDS = false; // R2 feature
export const DELAY_BEFORE_SENDING_SENSORS_SLEEP_POST_R3 = 9e3; // Need to wait this much time after APK launched for sensors sleep to get processed properly
export const SHOW_AVATAR_WHEN_CALIBRATED = true; // R2 feature
export const DEFAULT_NUMBER_OF_SENSORS = 7; // how many sensors total. Can be less for phantom limb.
export const SHOW_INDIVIDUAL_CALIBRATION = false; // Currently not supported, and the values should be ignored
export const SENSORS_CALIBRATION_TIMEOUT = 30e3; // timeout for the calibration phase
export const SENSORS_SYNCHRONIZATION_TIMEOUT = 45e3; // timeout for syncs after the initial one
export const SENSORS_INITIAL_SYNCHRONIZATION_TIMEOUT = 180e3; // allow 3 minutes for initial sync to give time to attach sensors
export const PAUSE_AT_HEADSET_ON_PATIENT_STEP = 2e3; // how long to show graphic
export const PAUSE_AT_SYNCHRONIZING_SENSORS_STEP = 2e3; // how long to show detecting text
export const PAUSE_AT_CALIBRATION_SENSORS_STEP = 2e3; // how long to show calibration
export const VISUALIZE_RAW_SENSORS_DURING_SYNC = false;
export const TDMA_STATS_ENTRIES = 30; // how many values to keep
export const BATTERY_DETAILS_CLICKS = 5; // how many clicks to bring up easter egg of battery details
export const DROPPED_PACKETS_DETAILS_CLICKS = Infinity; // how many clicks to bring up easter egg of battery details
export const RANDOMIZE_BLINKING = true; // whether to add a negative animation delay so animations start randomly
export const BLINK_ON_PERCENT = 50; // blink is on half the time. For less on, use a lower number
export const DROPPED_PACKET_MOVING_AVERAGE_WEIGHT = 1 / 60; // average = new * DROPPED_PACKET_WEIGHT + (1-DROPPED_PACKET_WEIGHT) * average
export const DROPPED_PACKET_STATS_ENTRIES = 5; // We keep 5 values here which is the last 5 seconds of data
export const DROPPED_PACKET_DISPLAY_FIELD = 'window'; // last, all, window, or movingAverage. See session reducer
export const RAW_SENSOR_INTERVAL_FOR_RAW_VIEW = 0.1; // using same freq as sensors
export const IGNORE_SENSOR_P_O = true; // don't process sensor position and orientation. we don't use it
export const HANDLE_AUTH_RESPONSE_PATHNAME = '/handleAuthResponse';
export const AUTH_RESPONSE_TIMEOUT = 15e3; // amount of time to wait for auth to complete. Should normally be quick
export const LOGOUT_ON_NETWORK_LOSS = true; // whether should simulate logout if network gone or keep UI alive
export const DROPPED_PACKET_LIMITS = {
  PERCENT_DROPPED: 50, // Any dropped packets over 25% get counted as over the limit.
  SECONDS_COUNT: 10, // If we are over the limit for over 10 seconds then we pop open the warning dialogs.
};
export const MAX_SENSOR_TIMEOUTS = 2;
export const SENSOR_BUFFER_TIME = 500; // how long in ms to buffer sensor data before using it for max calcs
export const INCLUDE_ENQUEUED_ROM_AT_END_ACTIVITY = true; // whether to include buffered rom or toss it when saving an activity
export const MINIMUM_HMD_REBOOT_TIME = 30e3; // It's currently more like 40. Will use this value to determine that maybe we need to reset sensors because we rebooted.
export const MOTION_DATA_WEBWORKER_SEND_INTERVAL = 2e3; // How often are buffered samples sent to the webworker for encoding and sending
export const MOTION_DATA_STORAGE_SEND_INTERVAL = 60e3; // How often are buffered batches sent to remote storage
export const MOTION_DATA_STORAGE_MAX_IN_FLIGHT = 1; // How many batches may be in flight before we cancel sending to storage. This is a proxy for network capacity.
export const NO_LAUNCHER = false; // get rid of this once launcher is in place.
export const INPUT_DEBOUNCE_TIME = 1000; // debounce inputs for 1 second to avoid spamming services and HMD.
export const ORDER_BY_TYPES = {
  category: 'category',
  user: 'user',
};
export const CURRENT_APK_INFO_POLLING_FREQUENCY = 2e3; // how often to retry reading CurrentApkInfo
export const LAUNCHER_APK = 'com.mvi.MVIHealth';
export const UNREAL = 'Unreal';
export const SINGLE_APP_MODE_APK = 'SINGLE_APP_MODE_APK';
export const CDN = 'https://cdn.realsystem.com';
export const APP_CONFIGURATION_FILE = `${CDN}/appConfigurations.json`;
export const MINIMIZE_AVATAR_API_CALLS = false; // if true, will only send deltas, otherwise, if false, sends all values
