import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom';
import { detect } from 'detect-browser';
import { MINIMUM_CHROME_VERSION } from './config/settings';

import './index.css';

window.onAndroidLog = (message, level, category) => { // handle logs coming from android. This handler will get replaced once app fully loads
  console.log('onAndroidLog received before startup:', message, level, category);
};

window.onAndroidAlert = (message) => { // handle alerts coming from android. This handler will get replaced once app fully loads
  console.warn('onAndroidAlert received before startup:', message);
};

const Body = lazy(() => import('./Body'));

// Use a trivial element for now for loading instead of the MUI one
const Elipsis = () => (<div>...</div>);

const Bootstrap = () => {
  // There is a tricky problem currently, in that the code itself won't compile on other browsers, so the chrome check needs to be done before
  // loading the bulk of the app
  const result = detect();
  if (result.type !== 'browser') return null;

  if (result?.name !== 'chrome' && result?.name !== 'chromium-webview') {
    return <div>{`You must use the chrome browser, version ${MINIMUM_CHROME_VERSION} or later.`}</div>;
  }
  if (Number(result.version.split('.')[0]) < MINIMUM_CHROME_VERSION) {
    return <div>{`You must use the chrome browser, version ${MINIMUM_CHROME_VERSION} or later.`}</div>;
  } 
  return (
    <Suspense fallback={<Elipsis />}>
      <Body />
    </Suspense>
  );
};

ReactDOM.render(<Bootstrap />, document.getElementById('root'));
